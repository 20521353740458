



































import { Component, Vue } from "vue-property-decorator";

@Component
export default class ErrorPage extends Vue {
  onBackPressed() {
    this.$router.push({ path: "/" });
  }
}
